import React from 'react';

import { depositions } from '../data';
import { SlideShow } from '../../../Carousel';

import * as S from './styles';

export function Depositions() {
  return (
    <S.SectionDepositions id="depositions">
      <SlideShow
        type="deposition"
        texts={depositions}
        size="xxmedium"
        weight="normal"
        align="left"
      />
    </S.SectionDepositions>
  );
}
