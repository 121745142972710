import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';

import PeopleConected from '../../../../static/exactalovers/people-conected.svg';
import Partner from '../../../../static/exactalovers/partner.svg';
import Confidence from '../../../../static/exactalovers/confidence.svg';
import Solutions from '../../../../static/exactalovers/solutions.svg';
import Respect from '../../../../static/exactalovers/respect.svg';
import Idea from '../../../../static/exactalovers/idea.svg';

import { Depositions } from '../Home/Depositions';
import Layout from '../../Layout';

const Image6 = '../../../../static/exactalovers/image6.png';
const Image7 = '../../../../static/exactalovers/image7.jpg';
const Image8 = '../../../../static/exactalovers/image8.png';

import * as S from './styles';

function ExactaLovers() {
  const intl = useIntl();

  return (
    <Layout>
      <S.Wrapper>
        <S.Main>
          <StaticImage src={Image6} alt="Pessoa deitada com bebe" />

          <S.Heading>
            <h1>
              {intl.formatMessage({ id: 'exactalovers-title-1' })}
              <br />

              <strong>
                {intl.formatMessage({ id: 'exactalovers-title-2' })}
              </strong>
            </h1>

            <p>{intl.formatMessage({ id: 'exactalovers-message' })}</p>

            <S.Link
              href="https://jobs.exactaworks.com.br/jobs"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {intl.formatMessage({ id: 'exactalovers-button' })}
            </S.Link>
          </S.Heading>

          <S.List>
            <S.ListItem>
              <img src={PeopleConected} alt="Icone de pessoas conectadas" />
              {intl.formatMessage({ id: 'essential-values-1' })}
            </S.ListItem>
            <S.ListItem>
              <img src={Partner} alt="Icone de mãos dadas" />
              {intl.formatMessage({ id: 'essential-values-4' })}
            </S.ListItem>
            <S.ListItem>
              <img src={Solutions} alt="Icone para novas soluções" />
              {intl.formatMessage({ id: 'essential-values-5' })}
            </S.ListItem>
            <S.ListItem>
              <img src={Respect} alt="Icone com pessoas dando a mão" />
              {intl.formatMessage({ id: 'essential-values-2' })}
            </S.ListItem>
            <S.ListItem>
              <img src={Idea} alt="Icone para novas ideias" />
              {intl.formatMessage({ id: 'essential-values-3' })}
            </S.ListItem>
            <S.ListItem>
              <img src={Confidence} alt="Icone de mãos se segurando" />
              {intl.formatMessage({ id: 'essential-values-6' })}
            </S.ListItem>
          </S.List>
        </S.Main>

        <S.Inovation>
          <StaticImage
            src={Image7}
            className="lovers-img-wrapper"
            height={800}
            objectFit="cover"
            alt={intl.formatMessage({ id: 'exactalovers-image-alt-1' })}
          />

          <S.Title>
            {intl.formatMessage({ id: 'exactalovers-innovative-title' })}
          </S.Title>

          <p>
            {intl.formatMessage({
              id: 'exactalovers-innovative-message-1'
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'exactalovers-innovative-message-2'
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'exactalovers-innovative-message-3'
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'exactalovers-innovative-message-4'
            })}
          </p>

          <S.Link
            href="https://jobs.exactaworks.com.br/jobs"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {intl.formatMessage({ id: 'navigation-link-work-with-us' })}
          </S.Link>
        </S.Inovation>

        <S.Commitment>
          <StaticImage
            src={Image8}
            alt={intl.formatMessage({ id: 'exactalovers-image-alt-2' })}
          />
          <S.Title>
            {intl.formatMessage({ id: 'exactalovers-team-members-title' })}
          </S.Title>
          <p>
            {intl.formatMessage({ id: 'exactalovers-team-members-message' })}
          </p>

          <S.Link
            href="https://jobs.exactaworks.com.br/jobs"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {intl.formatMessage({ id: 'exactalovers-open-positions' })}
          </S.Link>
        </S.Commitment>

        <Depositions />
      </S.Wrapper>
    </Layout>
  );
}

export default ExactaLovers;
