import styled from 'styled-components';
import background from '../../../../../static/images/depositions-background.png';
import { device } from '../../../../styles/media';

export const SectionDepositions = styled.section`
  display: flex;
  background: url(${background}) no-repeat;
  background-position: right bottom;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    height: 88rem;
    flex-direction: row;
  }
`;
